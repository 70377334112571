






















import { Vue, Component } from 'vue-property-decorator';
import inButton from '@/components/base/InsButton.vue';
@Component({
  components: { inButton: inButton }
})
export default class InsComfirm extends Vue {
    public Title!:string;
    public Content!:string;
    public Visible:boolean = false;
    public Confirm!:Function;
    public Cancel!:Function;
    private LayerVisible:boolean = true;

    get isMobile () {
      return this.$store.state.isMobile;
    }

    created () {
      // document.body.style.overflow = 'hidden';
    }
    doConfirm () {
      Vue.prototype.$ClearSingtonConfirm();
      if (this.Confirm && this.Confirm instanceof Function) { this.Confirm(); };
      this.Visible = false;
      setTimeout(() => { this.LayerVisible = false; });
      // document.body.style.overflow = '';
    }
    doCacel () {
      Vue.prototype.$ClearSingtonConfirm();
      if (this.Cancel && this.Cancel instanceof Function) { this.Cancel(); };
      this.Visible = false;
      setTimeout(() => { this.LayerVisible = false; });
    }

    mounted () {
      console.log(this.isMobile, 'isMobile confirm');
    }
}
