



import { Vue, Prop, Component } from 'vue-property-decorator';
@Component
export default class InsButton extends Vue {
  @Prop() private readonly nama!: string;
  @Prop() private readonly width!: string;
  @Prop() private readonly height!: string;
  @Prop() private readonly type!: string;
  @Prop() private readonly size!: string;
  @Prop() private readonly action!: string;
  @Prop({ default: false }) private readonly borderRadius!:boolean;
  private MyType = '';
  private MySize = '';
  get BtnStyle (): string {
    let Width = this.width;
    let Height = this.height;
    let Style =
      (Width ? 'width:' + Width + ';' : '') +
      (Height ? 'height:' + Height + ';' : '');
    return Style;
  }
  click (): void {
    if (this.action) {
      this.$emit('click', this.action);
    } else {
      this.$emit('click');
    }
  }
  created () {
    this.MyType = this.type ? this.type : 'primary';
    this.MySize = this.size ? this.size : 'large';
  }
}
