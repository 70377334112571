import { render, staticRenderFns } from "./InsConfirm.vue?vue&type=template&id=492e762c&scoped=true&"
import script from "./InsConfirm.vue?vue&type=script&lang=ts&"
export * from "./InsConfirm.vue?vue&type=script&lang=ts&"
import style0 from "./InsConfirm.vue?vue&type=style&index=0&id=492e762c&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "492e762c",
  null
  
)

export default component.exports