





import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
@Component
export default class InsInform extends Vue {
    public Message!:string;
    public Type:string = 'primary';
    public Visible:boolean = false;
    public Time:number = 2000;
    created () {
      window.setTimeout(() => {
        this.Visible = false;
        Vue.prototype.informCount--;
      }, this.Time);
    }
}
