








import { Vue, Prop, Component } from 'vue-property-decorator';
@Component
export default class InsNotice extends Vue {
    public Content!:string;
    public Title!:string;
    public Type:string = 'primary';
    public Visible:boolean = false;
    public Time:number = 5000;
    created () {
      window.setTimeout(() => {
        this.Visible = false;
        Vue.prototype.noticeCount--;
      }, this.Time);
    }
    get style () {
      return 'transform: translate(10%,' + (Vue.prototype.noticeCount * 130 - 50) + '%);';
    }
}
