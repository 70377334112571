








import { Component, Vue } from 'vue-property-decorator';
import { Loading } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { FrontE } from '@/sdk/common/SysConst';
@Component
export default class InsLayer extends Vue {
  public show:boolean = false;

  get isMobile () {
    return this.$store.state.isMobile;
  }

  created () {
  }

  get pcBuilding () {
    return FrontE.pcBuilding;
  }
  mounted () {
    if (this.$refs.layerbody) {
      Loading.service({
        target: this.$refs.layerbody as any,
        text: 'Loading',
        fullscreen: false,
        spinner: 'el-icon-loading'
      });
    }
  }
  updated () {
    if (this.$refs.layerbody) {
      Loading.service({
        target: this.$refs.layerbody as any,
        text: 'Loading',
        fullscreen: false,
        spinner: 'el-icon-loading'
      });
    }
  }
  public hidden () {
    this.show = false;
    document.body.style.overflowY = 'auto';
  }
  public showL () {
    this.show = true;
    setTimeout(() => { window.scrollTo(0, 0); document.body.style.overflowY = 'hidden'; }, 50);
  }
}
